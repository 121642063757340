import { TrackClient } from '@unicornsprings/track-client-sdk-js';
import Vue from 'vue';

// NOTE: the server must implement the /track API as a proxy for the track service and include the track organization id in the forwarded requests
function setup() {
    const track = new TrackClient({ endpoint: '/', serviceEndpoint: '/track' });
    Object.defineProperty(Vue.prototype, '$track', {
        value: track,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

export default { setup };
