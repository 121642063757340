import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        /*
        In the main website, the front page should have Unicorn Springs branding and
        show a search box that submits to the search page.
        In a custom hostname website, the front page should have the default brandprofile
        for that hostname, and show a search box that submits to the search page.
        */
        {
            path: '/',
            name: 'front', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/brand/:brandprofile',
            name: 'brand-front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/connect',
            name: 'connect', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "connect" */ '../views/Connect.vue'),
        },
        {
            path: '/brand/:brandprofile/connect',
            name: 'brand-connect',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "connect" */ '../views/Connect.vue'),
        },
        {
            path: '/brand/:brandprofile/cryptium-id',
            name: 'brand-cryptium-id',
            meta: { layout: 'brand-layout' },
            component: () => import(/* webpackChunkName: "cryptium-id" */ '../views/CryptiumID.vue'),
        },
        /*
        In the main website, allow searching for published brandprofiles across any organization.  TODO: remove this usage when we implement custom hostnames; then we will use customer.unicornsprings.com only for our own signup site (it will be the custom hostname used for unicorn springs itself) and we might implement a completley separate storefront web app that has a search feataure to find all participating organizations (and their published products) that opt-in to being found that way
        In a custom website, allow searching for published brandprofiles in the same organization.  TODO: repurpose this to finding only items in catalog for a brandprofile linked to the custom hostname being used.
        */
        {
            path: '/search',
            name: 'search', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout; and we should not allow a store search from here, because the same customer website will be used for custom ehostnames ... ifwe're going to allow a storef search it needs ot be a separate site
            meta: { layout: 'search-layout', isPublic: true, isVisibleWhenBrandNotFound: true },
            component: () => import(/* webpackChunkName: "search" */ '../views/catalog/CatalogSearch.vue'),
        },
        /*
        The item view is for any product type; buy button does different things for one-time vs subscription products
        */
        {
            path: '/item',
            name: 'main-catalog-item', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "catalog-item" */ '../views/catalog/CatalogItem.vue'),
        },
        {
            path: '/brand/:brandprofile/item',
            name: 'brand-catalog-item',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "catalog-item" */ '../views/catalog/CatalogItem.vue'),
        },
        {
            path: '/brand/:brandprofile/catalog',
            name: 'brand-catalog-search',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "catalog-search" */ '../views/catalog/CatalogSearch.vue'),
        },
        /*
        The transaction views comprise all the steps after selecting the items through payment and receipt.
        */
        {
            path: '/cart',
            name: 'main-cart', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "cart" */ '../views/transaction/Cart.vue'),
        },
        {
            path: '/brand/:brandprofile/cart',
            name: 'brand-cart',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "cart" */ '../views/transaction/Cart.vue'),
        },

        {
            path: '/cart/account',
            name: 'main-checkout-account', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-account" */ '../views/transaction/Account.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/account',
            name: 'brand-checkout-account',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-account" */ '../views/transaction/Account.vue'),
        },

        {
            path: '/cart/shipping',
            name: 'main-checkout-shipping', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-shipping" */ '../views/transaction/Shipping.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/shipping',
            name: 'brand-checkout-shipping',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-shipping" */ '../views/transaction/Shipping.vue'),
        },

        {
            path: '/cart/billing',
            name: 'main-checkout-billing', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-billing" */ '../views/transaction/Billing.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/billing',
            name: 'brand-checkout-billing',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-billing" */ '../views/transaction/Billing.vue'),
        },

        {
            path: '/cart/payment',
            name: 'main-checkout-payment', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-payment" */ '../views/transaction/Payment.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/payment',
            name: 'brand-checkout-payment',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-payment" */ '../views/transaction/Payment.vue'),
        },

        {
            path: '/cart/payment-status',
            name: 'main-checkout-payment-status', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-payment-status" */ '../views/transaction/PaymentStatus.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/payment-status',
            name: 'brand-checkout-payment-status',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-payment-status" */ '../views/transaction/PaymentStatus.vue'),
        },

        {
            path: '/cart/finalize',
            name: 'main-checkout-finalize', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-finalize" */ '../views/transaction/Finalize.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/finalize',
            name: 'brand-checkout-finalize',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "checkout-finalize" */ '../views/transaction/Finalize.vue'),
        },

        {
            path: '/cart/receipt',
            name: 'main-receipt', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "receipt" */ '../views/transaction/Receipt.vue'),
        },
        {
            path: '/brand/:brandprofile/cart/receipt',
            name: 'brand-receipt',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "receipt" */ '../views/transaction/Receipt.vue'),
        },
        /*
        The signup views are a subset of transaction views for subscription products (saas and membership) that may have multiple steps involved in signup, and also may need to skip some steps like reviewing cart items because it's already clear what user is subscribing to and there's nothing else in the cart; one-time purchases can start with the purchase view
        */
        {
            path: '/signup',
            name: 'main-transaction-signup', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/transaction/Signup.vue'),
        },
        {
            path: '/brand/:brandprofile/signup',
            name: 'brand-transaction-signup',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/transaction/Signup.vue'),
        },
        {
            path: '/brand/:brandprofile/signup-registration-closed',
            name: 'brand-transaction-signup-registration-closed',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup-registration-closed" */ '../views/transaction/SignupRegistrationClosed.vue'),
        },
        /*
        Authentication for returning customers
        */
        {
            path: '/login',
            name: 'login', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/brand/:brandprofile/login',
            name: 'brand-login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/brand/:brandprofile/sso',
            name: 'brand-sso-redirect-outbound',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "sso-redirect-outbound" */ '../views/SsoRedirectOutbound.vue'),
        },
        {
            path: '/brand/:brandprofile/download',
            name: 'brand-download',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "download" */ '../views/Download.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/brand/:brandprofile/interaction',
            name: 'brand-interaction',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },

        {
            path: '/brand/:brandprofile/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/AccountDashboard.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/settings',
            name: 'account-settings',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-settings" */ '../views/account/AccountSettings.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/close',
            name: 'account-close',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-close" */ '../views/account/CloseAccount.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/order-history',
            name: 'account-order-history',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-order-history" */ '../views/account/OrderHistory.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/payment-history',
            name: 'account-payment-history',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-payment-history" */ '../views/account/PaymentHistory.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/subscribe',
            name: 'account-subscription-create',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-subscription-create" */ '../views/account/SubscriptionCreate.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/service/edit',
            name: 'account-service-edit',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-service-edit" */ '../views/account/EditAccountService.vue'),
        },
        {
            path: '/brand/:brandprofile/account/:accountId/service/cancel',
            name: 'account-service-cancel',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "account-service-cancel" */ '../views/account/CancelAccountService.vue'),
        },

        // {
        //     path: '/dashboard',
        //     name: 'dashboard',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        // },
        {
            path: '/brand/:brandprofile/profile',
            name: 'profile',
            meta: { layout: 'brand-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/brand/:brandprofile/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/ProfileSetup.vue'),
        },
        {
            path: '/brand/:brandprofile/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/ProfileDelete.vue'),
        },
        {
            path: '/brand/:brandprofile/user-account-list',
            name: 'user-account-list',
            meta: { layout: 'brand-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        // NOTE: the interaction list route is still in development, and we're not linking to it from anywhere
        {
            path: '/brand/:brandprofile/user-interaction-list',
            name: 'user-interaction-list',
            meta: { layout: 'brand-layout' }, // why is this not brand-layout?
            component: () => import(/* webpackChunkName: "user-interaction-list" */ '../views/user/InteractionList.vue'),
        },
        {
            path: '/brand/:brandprofile/user-create-account',
            name: 'user-create-account',
            meta: { layout: 'brand-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        // {
        //     path: '/profile/authn/loginfront',
        //     name: 'profile-settings-authn-loginfront',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        // },
        // {
        //     path: '/profile/authz',
        //     name: 'profile-settings-authz',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        // },
        // {
        //     path: '/profile/session',
        //     name: 'profile-settings-session',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        // },
        // {
        //     path: '/webauthz/grant',
        //     name: 'webauthz-grant',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        // },
        // {
        //     path: '/webauthz/prompt',
        //     name: 'webauthz-prompt',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        // },
        {
            path: '/brand/:brandprofile/session',
            name: 'session', // TODO: remove the non-branded layouts... even for unicorn springs we should be using the branded layout
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "session" */ '../views/SessionPane.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
        {
            path: '/brand/:brandprofile/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'brand-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
