import BrandClient from './client/brandprofile';
import SiteClient from './client/site';
import MainClient from './client/main';
import UserClient from './client/user';
import AccountClient from './client/account';

/* eslint-disable dot-notation */

const clientMap = {}; // org id -> client configured for that organization
const client = {
    // NOTE: unlike in app-website-html5, here the organization is identified by brandprofile on the client side, and the server finds the organization id from that.
    site(brandprofile) {
        const idx = `site:${brandprofile}`;
        if (!clientMap[idx]) {
            const siteClient = new SiteClient({ endpoint: '/', serviceEndpoint: `/api/site/${brandprofile}` });
            siteClient.user = (userId) => {
                const userIdx = `${idx}:user:${userId}`;
                if (!clientMap[userIdx]) {
                    clientMap[userIdx] = new UserClient({ endpoint: '/', serviceEndpoint: `/api/site/${brandprofile}/user/${userId}` });
                }
                return clientMap[userIdx];
            };
            siteClient.account = (accountId) => {
                const accountIdx = `${idx}:account:${accountId}`;
                if (!clientMap[accountIdx]) {
                    clientMap[accountIdx] = new AccountClient({ endpoint: '/', serviceEndpoint: `/api/site/${brandprofile}/account/${accountId}` });
                }
                return clientMap[accountIdx];
            };
            clientMap[idx] = siteClient;
        }
        return clientMap[idx];
    },
    brandprofile() {
        if (!clientMap['brandprofile']) {
            clientMap['brandprofile'] = new BrandClient({ endpoint: '/', serviceEndpoint: '/api/brand' });
        }
        return clientMap['brandprofile'];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    // TODO: is this being used? because in customer website nearly all interactions MUST be linked to a brandprofile to identify the organization so we can look in that organization's data for user records; when we move to custom hostnames we won't need brandprofile in url so then the site(brandprofile) above will become redundant; but to avoid having to change a bunch of calls, probably all user apis need to be under the site ones, which means SiteClient will have a 'user' attribute with an instance of the User class, instead of getting it via the UserClient here.
    // user(id) {
    //     const idx = `user_${id}`;
    //     if (!clientMap[idx]) {
    //         clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: '/api/browser', userId: id });
    //     }
    //     return clientMap[idx];
    // },
};

export default client;
