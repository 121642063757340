import Vue from 'vue';
import MainLayout from '../layouts/MainLayout.vue';
import BrandLayout from '../layouts/BrandLayout.vue';
import SearchLayout from '../layouts/SearchLayout.vue';
// import DashboardLayout from '../layouts/DashboardLayout.vue';
// import SettingsLayout from '../layouts/SettingsLayout.vue';
// import WorkspaceLayout from '../layouts/WorkspaceLayout.vue';

function setup() {
    Vue.component('main-layout', MainLayout);
    Vue.component('brand-layout', BrandLayout);
    Vue.component('search-layout', SearchLayout);
    // Vue.component('dashboard-layout', DashboardLayout);
    // Vue.component('settings-layout', SettingsLayout);
    // Vue.component('workspace-layout', WorkspaceLayout);
}

export default { setup };
