/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class Service {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async getInfo() {
        return getJson(`${this.url}`);
    }

    async getVersion() {
        return getJson(`${this.url}/service/state/version`, null, { requestHeaders: this.requestHeaders });
    }

    // async getSettingsList() {
    //     return getJson(`${this.url}/service/state/config`, null, { requestHeaders: this.requestHeaders });
    // }

    // async editSetting(request) {
    //     return postJsonAcceptJson(`${this.url}/service/edit/config`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async getOrganizationList(request) {
    //     return getJson(`${this.url}/service/search/organization`, request, { requestHeaders: this.requestHeaders });
    // }

    // async getDomainList(request) {
    //     return getJson(`${this.url}/service/search/domain`, request, { requestHeaders: this.requestHeaders });
    // }

    // async deleteOrganization(request) {
    //     return postJsonAcceptJson(`${this.url}/service/organization/delete`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // async getTinydnsConfig() {
    //     return getJson(`${this.url}/service/state/tinydns`, null, { requestHeaders: this.requestHeaders });
    // }

    // async getC2Config() {
    //     return getJson(`${this.url}/service/state/c2`, null, { requestHeaders: this.requestHeaders });
    // }

    // async getLetsencryptConfig() {
    //     return getJson(`${this.url}/service/state/letsencrypt`, null, { requestHeaders: this.requestHeaders });
    // }

    // async setupLetsencryptConfig(request) {
    //     return postJsonAcceptJson(`${this.url}/service/edit/letsencrypt`, request, null, { requestHeaders: this.requestHeaders });
    // }
}

class CurrentUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: change all refereces to user(id).get()
    // async get() {
    //     return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all references to user(id).edit(request)
    // async edit(request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/current-user/edit`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all referfencces to user(id).delete()
    // async delete() {
    //     return postJsonAcceptJson(`${this.url}/current-user/delete`, null, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all refernces to user(id).getOrganizationList()
    // async getOrganizationList(request) {
    //     return getJson(`${this.url}/current-user/organization-list`, request, { requestHeaders: this.requestHeaders });
    // }

    // TODO: change all references to user(id).createOrganization(request)
    // async createOrganization(request) {
    //     return postJsonAcceptJson(`${this.url}/current-user/create/organization`, request, null, { requestHeaders: this.requestHeaders });
    // }
}

class BrowserClient {
    constructor(context = {}) {
        this.currentUser = new CurrentUser(context);
        this.service = new Service(context);
    }
}

export default BrowserClient;
export {
    CurrentUser,
    Service,
    // Webauthz,
};
