/*!
Copyright (C) 2022 Liberty Infrasystems. All rights reserved.
*/

/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

import { getJson, postJsonAcceptJson } from '@libertyio/axios-util-js';

class Authn {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/authn`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}/session`, request, { requestHeaders: this.requestHeaders });
    }

    async deleteSession(request) {
        return postJsonAcceptJson(`${this.url}/session/delete`, request, null, { requestHeaders: this.requestHeaders });
    }

    async signup(request) {
        return postJsonAcceptJson(`${this.url}/signup`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/login/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkLogin(request) {
        return postJsonAcceptJson(`${this.url}/login/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async prefsRedirect(request) {
        return postJsonAcceptJson(`${this.url}/prefs-redirect`, request, null, { requestHeaders: this.requestHeaders });
    }

    async ssoRedirect(request) {
        return postJsonAcceptJson(`${this.url}/sso/redirect`, request, null, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/logout`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/email/start`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkVerifyEmail(request) {
        return postJsonAcceptJson(`${this.url}/email/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startDownload(request) {
        return postJsonAcceptJson(`${this.url}/download/start`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/*
class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/data`;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/product`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(query) {
        return getJson(`${this.url}/state/user`, query, { requestHeaders: this.requestHeaders });
    }

    // async edit(query, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit/product`, request, query, { requestHeaders: this.requestHeaders });
    // }

    // async delete(query) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/delete/product`, null, query, { requestHeaders: this.requestHeaders });
    // }

    // TODO: this needs to move into account for 'search linked users' and into admin service (not here at all) for 'search all users'
    async search(query) {
        return getJson(`${this.url}/search/user`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

class Product {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/data`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/product`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/product`, query, { requestHeaders: this.requestHeaders });
    }
}

class Cart {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/cart`;
        this.requestHeaders = context.requestHeaders;
    }

    // retrieve contents of the 'shopping cart': which items are in it, how many, etc.
    async get(query) {
        return getJson(`${this.url}`, query, { requestHeaders: this.requestHeaders });
    }

    // add or remove items from the cart, or change the quantity of items in the cart
    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete`, null, query, { requestHeaders: this.requestHeaders });
    }

    async payment(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/payment`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkPaymentStatus(request) {
        return postJsonAcceptJson(`${this.url}/payment-status`, request, null, { requestHeaders: this.requestHeaders });
    }

    async finalize(request) {
        return postJsonAcceptJson(`${this.url}/finalize`, request, null, { requestHeaders: this.requestHeaders });
    }

    async activate(request) {
        return postJsonAcceptJson(`${this.url}/activate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async receipt(query) {
        return getJson(`${this.url}/receipt`, query, { requestHeaders: this.requestHeaders });
    }
}

/*
class LinkAccountUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/data`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // query must include both account_id and user_id; only that link will be retrieved; a user can only retrieve their own link to an account, and an account admin can retrieve any linked user to an account on which they are the admin
    async get(query) {
        return getJson(`${this.url}/state/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }

    // query must include both account_id and user_id; only that link will be deleted; account admin can remove users from account with this API; users can also remove themselves from the account with this API but only if it doesn't cause the account to have no admin users left; for the last user who is an admin, when they close the account we will automatically remove their link from it
    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-account-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    // query must include both account_id and user_id; only that link will be edited; only account admin can edit user permissions on the account with this API
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-account-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    // query must include account_id, user_id, or both (which is like 'get' but returns empty list if not found, instead of 404 error) a user can only retrieve their own link to an account, and an account admin can retrieve any linked user to an account on which they are the admin
    async search(query) {
        return getJson(`${this.url}/search/link-account-user`, query, { requestHeaders: this.requestHeaders });
    }
}
*/

class Setting {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/data`;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-account-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-account-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // query must include 'name'; only read-only settings readable to customer website would be returned, such as authentication_mode
    async get(query) {
        return getJson(`${this.url}/state/setting`, query, { requestHeaders: this.requestHeaders });
    }
}

class Interaction {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/interaction`;
        this.requestHeaders = context.requestHeaders;
    }

    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/interaction/create`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async get(id) {
        return getJson(`${this.url}`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resume(token) {
        return postJsonAcceptJson(`${this.url}/resume`, { token }, null, { requestHeaders: this.requestHeaders });
    }

    async edit(id, message) {
        return postJsonAcceptJson(`${this.url}/edit`, message, { id }, { requestHeaders: this.requestHeaders });
    }

    // async getTokenStatus(tokenId) {
    //     console.log('getTokenStatus');
    //     return getJson(`${this.url}/interaction/token/status`, { tokenId }, { requestHeaders: this.requestHeaders });
    // }
    async search(query) {
        return getJson(`${this.url}/search`, query, { requestHeaders: this.requestHeaders });
    }
}

class Organization {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/organization`;
        this.requestHeaders = context.requestHeaders;
    }

    async get() {
        return getJson(`${this.url}`, null, { requestHeaders: this.requestHeaders });
    }
}

class Webauthz {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/webauthz`;
        this.requestHeaders = context.requestHeaders;
    }

    // for authorization server
    async getAccessPrompt(id) {
        return getJson(`${this.url}/prompt`, { id }, { requestHeaders: this.requestHeaders });
    }

    // for authorization server
    async grantAccess(id, permit = {}) {
        return postJsonAcceptJson(`${this.url}/prompt`, { id, submit: 'grant', permit }, { requestHeaders: this.requestHeaders });
    }

    // for authorization server
    async denyAccess(id) {
        return postJsonAcceptJson(`${this.url}/prompt`, { id, submit: 'deny' }, { requestHeaders: this.requestHeaders });
    }

    // for client application
    async finishWebauthzRequest(query, request) {
        return postJsonAcceptJson(`${this.url}/grant`, request, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a client for the browser and it uses session cookies to authenticate to the server.
 */
class BrowserClient {
    constructor(context = {}) {
        this.authn = new Authn(context);
        this.interaction = new Interaction(context);
        this.product = new Product(context);
        this.cart = new Cart(context);
        this.organization = new Organization(context);
        this.setting = new Setting(context);
        // this.user = new User(context);
        this.webauthz = new Webauthz(context);
    }
}

export default BrowserClient;

export {
    Authn,
    // CurrentOrganization,
    Interaction,
    Product,
    Cart,
    // LinkAccountUser,
    Organization,
    Setting,
    // User,
    Webauthz,
};
