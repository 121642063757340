import Vue from 'vue';

/**
 * The AppNavigator plugin is a wrapper around the `vue-router` functions
 * `push` and `replace` that automatically inserts the `brandprofile` path
 * parameter and the `i` interaction id query parameter if they are in
 * the current application state. This saves a lot of repetitive code in
 * various views of the application.
 *
 * This plugin must be installed AFTER `vuex` and `vue-router` so it can
 * access the application state and the router instance.
 */

class AppNavigator {
    constructor({ store, router }) {
        this.store = store;
        this.router = router;
        this.namespace = 'cygnia.enterprise.nav';
    }

    /**
     * Stores the key-value pair in `sessionStorage`, which the browser persists
     * as long as the user stays on the same site in the same tab or window.
     * If the user reloads the page, the data in `sessionStorage` continues to
     * be available. Places all data in the `cygnia.enterprise.nav` namespace to
     * avoid conflicts with any other use of sessionStorage.
     * @param {*} key the storage key; will be stored in the `cygnia.namespace.nav` namespace
     * @param {*} value to store
     */
    set(key, value) {
        sessionStorage.setItem(`${this.namespace}:${key}`, value);
    }

    /**
     * Retrieves a key from `sessionStorage`.
     * @param {*} key the storage key; will be retrieved from the `cygnia.namespace.nav` namespace
     */
    get(key) {
        return sessionStorage.getItem(`${this.namespace}:${key}`);
    }

    // given a route like { name: 'user-profile' }, automatically add the `brandprofile` path parameter and `i` (interaction id) query parameter
    // if they're not already specified in the route
    route(inputRoute) {
        const {
            name,
            params = {},
            query = {},
            hash,
        } = inputRoute;
        if (this.store.state.brandprofile && !params.brandprofile) {
            params.brandprofile = this.store.state.brandprofile;
        }
        if (this.router.currentRoute.params.accountId && !params.accountId) {
            params.accountId = this.router.currentRoute.params.accountId; // TODO: what about store.state.accountId or store.state.account.id ?
        }
        if (this.store.state.interaction && !query.i) {
            query.i = this.store.state.interaction.id;
        }
        return {
            name,
            params,
            query,
            hash,
        };
    }

    push(route) {
        const edited = this.route(route);
        return this.router.push(edited);
    }

    replace(route) {
        const edited = this.route(route);
        return this.router.replace(edited);
    }
}

function plugin(app, options) {
    // app.prototype.$nav = new AppNavigator({ store: options.store, router: options.router });
    const nav = new AppNavigator({ store: options.store, router: options.router });
    Object.defineProperty(Vue.prototype, '$nav', {
        value: nav,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

// import client from '../sdk/client-factory';

function setup({ store, router }) {
    Vue.use(plugin, { store, router });
    /*
    // const endpoint = new URL(window.location).origin;
    Object.defineProperty(Vue.prototype, '$nav', {
        value: client,
        writable: false,
        enumerable: false,
        configurable: false,
    });
    */
}

export default { setup };
