/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

// TODO: for the operations on a specific account, we should put account id in the PATH so server account middleware can automatically check access without having to repeat that in every API; the search would be different since it's not for a specific account, it can do its own permission check.
class Account {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        // return getJson(`${this.url}/state/user`, query, { requestHeaders: this.requestHeaders });
        return getJson(this.url, query, { requestHeaders: this.requestHeaders });
    }

    // NOTE: moved to user.js
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
    // }

    // TODO: separate into check/billing, check/data, etc.
    async check(request) {
        return postJsonAcceptJson(`${this.url}/check`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async close(request) {
        return postJsonAcceptJson(`${this.url}/close`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class User {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/user`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/user`, query, { requestHeaders: this.requestHeaders });
    }

    // TODO: edit user permissions for the account
    // async edit(query, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit/user`, request, query, { requestHeaders: this.requestHeaders });
    // }

    // TODO: remove user from account
    // async delete(query) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/delete/user`, null, query, { requestHeaders: this.requestHeaders });
    // }
}

// class Account {
//     constructor(context) {
//         // this.url = `${context.serviceEndpoint}/user/${context.userId}`;
//         this.url = context.serviceEndpoint;
//         this.requestHeaders = context.requestHeaders;
//     }

//     // async edit(query, request) {
//     //     // NOTE: you only need to specify the attributes that should be changed
//     //     return postJsonAcceptJson(`${this.url}/edit/product`, request, query, { requestHeaders: this.requestHeaders });
//     // }

//     // async delete(query) {
//     //     // NOTE: you only need to specify the attributes that should be changed
//     //     return postJsonAcceptJson(`${this.url}/delete/product`, null, query, { requestHeaders: this.requestHeaders });
//     // }

//     /*
//     async check(request) {
//         return postJsonAcceptJson(`${this.url}/check`, request, null, { requestHeaders: this.requestHeaders });
//     }

//     async get() {
//         return getJson(`${this.url}/state`, null, { requestHeaders: this.requestHeaders });
//     }

//     async edit(request) {
//         // NOTE: you only need to specify the attributes that should be changed
//         return postJsonAcceptJson(`${this.url}/edit`, request, null, { requestHeaders: this.requestHeaders });
//     }

//     async delete() {
//         return postJsonAcceptJson(`${this.url}/delete`, null, null, { requestHeaders: this.requestHeaders });
//     }

//     async search(request) {
//         return getJson(`${this.url}/search`, request, { requestHeaders: this.requestHeaders });
//     }

//     async getAccountList(request) {
//         return getJson(`${this.url}/search/account`, request, { requestHeaders: this.requestHeaders });
//     }

//     async createAccount(request) {
//         return postJsonAcceptJson(`${this.url}/create/account`, request, null, { requestHeaders: this.requestHeaders });
//     }

//     // async create(request) {
//     //     return postJsonAcceptJson(`${this.url}/user/create`, request, null, { requestHeaders: this.requestHeaders });
//     // }

//     // // TODO: move to unicorn springs
//     // async invite(request) {
//     //     return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
//     // }

//     // // request like { email (string), agreeToTerms (boolean) }
//     // // TODO: move to unicorn springs
//     // async activate(request) {
//     //     return postJsonAcceptJson(`${this.url}/user/activate`, request, null, { requestHeaders: this.requestHeaders });
//     // }

//     // // TODO: move to unicorn springs
//     // async list(request) {
//     //     return getJson(`${this.url}/search/user`, request, { requestHeaders: this.requestHeaders });
//     // }

//     // async get(id) {
//     //     return getJson(`${this.url}/user`, { id }, { requestHeaders: this.requestHeaders });
//     // }

//     // // TODO: move to unicorn springs
//     // async edit(id, request) {
//     //     // NOTE: you only need to specify the attributes that should be changed
//     //     return postJsonAcceptJson(`${this.url}/user/edit`, request, { id }, { requestHeaders: this.requestHeaders });
//     // }

//     // // TODO: move to unicorn springs
//     // async delete({ id }) {
//     //     return postJsonAcceptJson(`${this.url}/user/delete`, null, { id }, { requestHeaders: this.requestHeaders });
//     // }
//     */
// }

class AccountService {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/account-service`, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/account-service`, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query, request) {
        return postJsonAcceptJson(`${this.url}/delete/account-service`, request, query, { requestHeaders: this.requestHeaders });
    }
}

class Order {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/order`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/order`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/order`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/order`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/order`, query, { requestHeaders: this.requestHeaders });
    }
}

class Invoice {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invoice`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/invoice`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invoice`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invoice`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invoice`, query, { requestHeaders: this.requestHeaders });
    }
}

class Payment {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/payment`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/payment`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/payment`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/payment`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/payment`, query, { requestHeaders: this.requestHeaders });
    }
}

class Refund {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/refund`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/state/refund`, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/refund`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/refund`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/refund`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * TODO: remove from customer website, unless w will have ability for customer to invite soeone else to manage their account, which could happen for a household account or a business account
 */
class Invite {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/data`;
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/invite`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invite`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invite`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invite`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.self = new Account(context);
        this.invite = new Invite(context);
        this.accountService = new AccountService(context);
        this.payment = new Payment(context);
        this.refund = new Refund(context);
        this.order = new Order(context);
        this.invoice = new Invoice(context);
        this.user = new User(context);
    }
}

export default BrowserClient;
export {
    Account,
    AccountService,
    Payment,
    Refund,
    Order,
    Invoice,
    Invite,
    User,
};
