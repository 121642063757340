import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

function shouldSkip({ state, commit, label, force = false }) {
    if (Number.isInteger(state.fnTsMap[label]) && state.fnTsMap[label] + state.fnSkipDuration > Date.now() && !force) {
        return true;
    }
    commit('fnTsMap', { [label]: Date.now() });
    return false;
}

export default new Vuex.Store({
    state: {
        focus: null,
        brandselector: null, // 'brandprofile' (use brandprofile from path) or 'hostname' (use hostname part of URL to lookup brandprofile)
        brandprofile: null,
        hostname: null,
        organization: null,
        brand: null,
        palette: null,
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use Emplus brand colors, the flicker is very noticeable
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#BDBDBD', // grey lighten-1
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },
        brandNotFoundError: false,
        interaction: null, // current interaction { id, type, not_after, state }
        cart: null, // for checkout
        isAuthenticatedReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and organization info from server; this flag prevents the UI from flickering a "you're not authenticated" message while we load the user's session to find out they actually ARE authenticated
        serviceInfo: {}, // registration_mode, stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // userId, isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        account: null, // current account selected for /account, /signup, or /cart routes
        accountList: [], // account list loaded for navbar and user-account-list view
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: {},
        fnSkipDuration: 1000, // milliseconds; skip function call if already called within the last `fnSkipDuration` milliseconds unless `force` parameter is provided
        fnTsMap: {}, // request timestamp map for function name => last ran so we can avoid duplicate invocations
        accountTypeChoices: [ // TODO: load these from server to set this list
            {
                text: 'Individual',
                value: 'individual',
                icon: ['fas', 'user'],
                description: 'The account will belong to you alone.',
            },
            {
                text: 'Team',
                value: 'team',
                icon: ['fas', 'user-friends'],
                description: 'The account will be shared with other individuals. You will be the owner and administrator.',
            },
            {
                text: 'Enterprise',
                value: 'enterprise', // TODO: update @unicornsprings/enterprise-api-constants-node-js to replace the 'company' value with 'enterprise'
                icon: ['fas', 'users'],
                description: 'The account will belong to a company or agency. You will be the administrator.',
            },
        ],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        isCustomDomain(state) {
            return state.hostname !== process.env.VUE_APP_HOSTNAME;
        },
        mainWebsiteURL() {
            return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://unicornsprings.com';
        },
        brandName(state) {
            return state.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.primary) && state.palette.content.primary.length > 0) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.accent) && state.palette.content.accent.length > 0) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
        interactionId(state) {
            return state.interaction?.id ?? null;
        },
    },
    mutations: {
        isAuthenticatedReady(state) {
            console.log('vuex store: isAuthenticatedReady');
            state.isAuthenticatedReady = true;
        },
        focus(state, value) {
            state.focus = value;
        },
        brandselector(state, brandselector) {
            // console.log(`vuex store: set brandselector to ${brandselector}`);
            state.brandselector = brandselector;
        },
        brandprofile(state, brandprofile) {
            // console.trace(`vuex store: set brandprofile to ${brandprofile}`);
            state.brandprofile = brandprofile;
        },
        hostname(state, hostname) {
            // console.log(`vuex store: set hostname to ${hostname}`);
            state.hostname = hostname;
        },
        organization(state, organization) {
            state.organization = organization;
        },
        brand(state, brand) {
            state.brand = brand;
        },
        palette(state, palette) {
            state.palette = palette;
        },
        brandNotFoundError(state, value) {
            state.brandNotFoundError = value;
        },
        cart(state, value) {
            state.cart = value;
        },
        setServiceInfo(state, serviceInfo) {
            state.serviceInfo = serviceInfo;
        },
        setSession(state, session) {
            state.session = session;
        },
        user(state, user) {
            state.user = user;
        },
        account(state, account) {
            state.account = account;
        },
        accountList(state, accountList) {
            state.accountList = accountList;
        },
        setInteraction(state, interaction) {
            state.interaction = interaction;
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        fnTsMap(state, update) {
            state.fnTsMap = { ...state.fnTsMap, ...update };
        },
    },
    actions: {
        // async createOrganization({ commit, dispatch, state }, organizationInfo) {
        //     commit('loading', { createOrganization: true });
        //     const response = await client.user.create(organizationInfo);
        //     if (response.isCreated) {
        //         await dispatch('loadSession');
        //         if (state.session.isAuthenticated) {
        //             await dispatch('loadUser');
        //             // await dispatch('loadOrganization');
        //         }
        //     }
        //     commit('loading', { createOrganization: false });
        //     return response;
        // },
        async logout({ commit, state }) {
            commit('loading', { logout: true });
            if (state.brandprofile) {
                await client.site(state.brandprofile).authn.logout();
            } else {
                await client.main().authn.logout(); // TODO: this isn't really supported in the multi-site format here; but when we have custom hostnames we won't need to keep sending brandprofile with every api request, it will be detected from the hostnam, so then we'll go back to using main() isntead of site(x)
            }
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.main().authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async refresh({ commit, dispatch, state }, { progressIndicator = false } = {}) {
            console.log('vuex store: refresh');
            // not displaying loading bar by default because we want this to be transparent; it's distracting to show that loading bar every time the user returns to the page from somewhere
            try {
                await dispatch('loadSession', { progressIndicator, force: true });
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser', { progressIndicator, force: true });
                } else {
                    commit('user', null);
                    commit('account', null);
                }
                commit('isAuthenticatedReady', true);
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true, force = false } = {}) {
            if (shouldSkip({ commit, state, label: 'loadSession', force })) {
                return;
            }
            try {
                if (progressIndicator) {
                    commit('loading', { loadSession: true });
                }
                // const sessionInfo = await client.main().authn.get();
                const sessionInfo = await client.site(state.brandprofile).authn.get();
                console.log(`vuex store: session ${JSON.stringify(sessionInfo)}`);
                const now = Date.now();
                const {
                    user_id: userId,
                    authenticated = false,
                    authenticated_duration: authenticatedDuration = null,
                    unlocked = false,
                    unlocked_duration: unlockedDuration = null,
                    // duration = null,
                    refresh_after_duration: refreshAfterDuration = null,
                    etag = {},
                } = sessionInfo;
                let { reloadTimeoutId } = state.session;
                if (authenticated && typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0) {
                    // clear a previous timeout, if it exists
                    if (reloadTimeoutId) {
                        console.log(`vuex store: clearing timeout ${reloadTimeoutId}`);
                        clearTimeout(reloadTimeoutId);
                    }
                    console.log(`vuex store: scheduling session reload for ${refreshAfterDuration} ms`);
                    reloadTimeoutId = setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, refreshAfterDuration);
                }
                commit('setSession', {
                    userId,
                    isAuthenticated: authenticated,
                    isUnlocked: unlocked,
                    authenticatedNotAfter: typeof authenticatedDuration === 'number' && authenticatedDuration > 0 ? now + authenticatedDuration : null,
                    unlockedNotAfter: typeof unlockedDuration === 'number' && unlockedDuration > 0 ? now + unlockedDuration : null,
                    nextRefresh: typeof refreshAfterDuration === 'number' && refreshAfterDuration > 0 ? now + refreshAfterDuration : null,
                    etag,
                    reloadTimeoutId,
                });
                console.log(`vuex store: session authenticated? ${authenticated}`);
                if (authenticated && userId) {
                    dispatch('loadUser', { progressIndicator, force });
                } else {
                    commit('user', null);
                    commit('account', null);
                }
            } catch (err) {
                commit('setSession', { fault: { type: 'read-failed' } });
            } finally {
                commit('loading', { loadSession: false });
            }
        },
        async loadUser({ commit, state }, { progressIndicator = true, force = false } = {}) {
            if (shouldSkip({ commit, state, label: 'loadUser', force })) {
                return;
            }
            try {
                if (progressIndicator) {
                    commit('loading', { loadUser: true });
                }
                console.log(`vuex store: loadUser state.session.userId? ${state.session.userId}`);
                if (state.session.userId) {
                    const userInfo = await client.site(state.brandprofile).user(state.session.userId).self.get();
                    console.log(`vuex store: loadUser userInfo? ${JSON.stringify(userInfo)}`);
                    commit('user', userInfo);
                } else {
                    commit('user', null); // { fault: { type: 'read-failed' } }
                }
            } catch (err) {
                console.error('vuex store: loadUser failed', err);
                commit('user', null); // { fault: { type: 'read-failed' } }
            } finally {
                commit('loading', { loadUser: false });
            }
        },
        async loadAccount({ commit, state }, { accountId, progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccount: true });
                }
                const accountInfo = await client.site(state.brandprofile).account(accountId).self.get();
                commit('account', accountInfo);
            } catch (err) {
                console.error('failed to load account', err);
                commit('account', null);
            } finally {
                commit('loading', { loadAccount: false });
            }
        },
        async loadCart({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadCart: true });
                }
                const cart = await client.site(state.brandprofile).cart.get();
                commit('cart', cart);
            } catch (err) {
                console.error('failed to load cart', err);
                commit('cart', null);
            } finally {
                commit('loading', { loadCart: false });
            }
        },
        async loadAccountList({ commit, state }, { filters = {}, progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadAccountList: true });
                }
                if (state.session.userId) {
                    // const response = await client.user(state.session.userId).user.getAccountList();
                    const response = await client.site(state.brandprofile).user(state.session.userId).account.search({ ...filters }); // filters can be { account_id } or { account_name } or { is_open }
                    commit('accountList', response.list);
                } else {
                    commit('accountList', []); // { fault: { type: 'read-failed' } }
                }
            } catch (err) {
                console.error('vuex store: loadAccountList failed', err);
                commit('accountList', []); // { fault: { type: 'read-failed' } }
            } finally {
                commit('loading', { loadAccountList: false });
            }
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.main().authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.user(state.session.userId).user.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('user', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        /*
        async createInteraction({ commit }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.main().interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', response);
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        */
        async editInteraction({ commit, state }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            // const response = await client.main().interaction.edit(interactionId, message);
            const response = await client.site(state.brandprofile).interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', response);
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit, state }, interactionId) {
            try {
                commit('loading', { storeloadInteraction: true });
                // TODO: use etag/head to check with server if interaction changed since last time we retrieved it?
                // const response = await client.main().interaction.get(interactionId);
                const response = await client.site(state.brandprofile).interaction.get(interactionId);
                commit('setInteraction', response);
                return response;
            } catch (err) {
                console.error(`store: loadInteraction failed: ${interactionId}`, err);
                return null;
            } finally {
                commit('loading', { storeloadInteraction: false });
            }
        },
        async loadBrand({ commit, state }) {
            try {
                commit('loading', { loadBrand: true });
                let request;
                if (state.brandselector === 'brandprofile') {
                    request = { brandprofile: state.brandprofile };
                } else {
                    request = { hostname: state.hostname };
                }
                const response = await client.brandprofile().brand.get(request);
                commit('brand', response);
                if (state.brandprofile === null || state.brandprofile !== response.alias) {
                    console.log(`store: loadBrand: brandprofile was ${state.brandprofile} but response from server shows ${response.alias}, updating`);
                    commit('brandprofile', response.alias);
                }
            } catch (err) {
                console.error('loadBrand failed', err);
                commit('brand', null);
                commit('brandNotFoundError', true);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit, state }, { mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                let request;
                if (state.brandselector === 'brandprofile') {
                    request = { brandprofile: state.brandprofile };
                } else {
                    request = { hostname: state.hostname };
                }
                const match = {
                    ...request,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                commit('palette', response);
            } catch (err) {
                console.error('loadPalette failed', err);
                commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
        async loadOrganization({ commit, state }) {
            try {
                commit('loading', { storeLoadOrganization: true });
                const response = await client.site(state.brandprofile).organization.get();
                if (response?.type === 'item' && response.item) {
                    commit('organization', response.item);
                    return response.item;
                }
                return null;
            } catch (err) {
                console.error(`store: loadOrganization failed: ${state.brandprofile}`, err);
                return null;
            } finally {
                commit('loading', { storeLoadOrganization: false });
            }
        },
    },
});
